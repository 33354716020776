<template>
  <div class="container">
    <div class="topnav_placeholder"></div>
  </div>
</template>

<script>
export default {
  name: 'QbTopnavPlaceholder'
}
</script>

<style lang="less" scoped>
.topnav_placeholder {
  width: 100%;
  height: 113px;
}
</style>
