<template>
  <div id="app">
    <router-view> </router-view>
  </div>
</template>

<script>
import { isMobile } from './utils'
export default {
  created() {
    if (isMobile()) {
      window.location.href = 'http://m.quanbanggroup.cn/'
    }
  }
}
</script>

<style lang="less"></style>
