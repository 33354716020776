<template>
  <div class="home_container">
    <!-- home-banner -->
    <HomeBanner></HomeBanner>

    <!-- 商业模式 -->
    <BusiNess></BusiNess>

    <!-- 泉邦金牌课程 -->
    <HomeCourse id="anchor-course"></HomeCourse>

    <!-- 泉邦导师介绍 -->
    <HomeMember id="anchor-lecturer"></HomeMember>

    <!-- 泉邦家族 -->
    <QbFamily id="anchor-family"></QbFamily>
  </div>
</template>

<script>
import HomeBanner from './components/home-banner.vue'
import BusiNess from './components/home-business.vue'
import HomeCourse from './components/home-course.vue'
import HomeMember from './components/home-member.vue'
export default {
  name: 'Home',
  components: {
    HomeBanner,
    BusiNess,
    HomeCourse,
    HomeMember
  }
}
</script>

<style lang="less" scoped></style>
