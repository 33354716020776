<template>
  <div class="layout">
    <!-- 顶部导航 -->
    <div class="topnav">
      <TopNav
        :color="color"
        :hrShow="hrShow"
        :logo="logo"
        @handleChangeMnue="handleChangeMnue"
        :active="active"
      ></TopNav>
    </div>
    <!-- 动画菜单分类列表 -->
    <div
      class="menu_category_list"
      @mouseenter="handleChangeMnue(true)"
      @mouseleave="handleChangeMnue(false)"
      :class="{ show: active }"
    >
      <MenuCategoryList></MenuCategoryList>
    </div>
    <!-- 二级路由出口 -->
    <router-view></router-view>
    <!-- 尾部 -->
    <div class="footer">
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import TopNav from './components/topnav'
import Footer from './components/footer'
import MenuCategoryList from './components/menu-category-list'
export default {
  components: {
    TopNav,
    Footer,
    MenuCategoryList
  },
  data() {
    return {
      color: '',
      hrShow: true,
      logo: '',
      active: false
    }
  },
  created() {
    this.color = this.$route.meta.color
    this.hrShow = this.$route.meta.hrShow
    this.logo = this.$route.meta.logo
  },

  beforeRouteUpdate(to, from, next) {
    this.color = to.meta.color
    this.hrShow = to.meta.hrShow
    this.logo = to.meta.logo
    this.active = false
    next()
  },

  methods: {
    handleChangeMnue(flag) {
      this.active = flag
    }
  }
}
</script>

<style>
.layout {
  position: relative;
}
.menu_category_list {
  position: absolute;
  top: 0px;
  width: 100%;
  background-color: #000834;
  display: none;
  background-color: rgba(0, 0, 52, 0.2);
  z-index: 1001;
}

.show {
  display: block;
  animation: topin 0.6s;
}

@keyframes topin {
  0% {
    transform: translateY(-10px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
</style>
