<template>
  <div class="home-business-container">
    <div class="business-mode">
      <div class="business-title">
        <div class="business-title-left">全方位帮扶企业塑造商业模式</div>
        <div
          class="business-title-right"
          @click="$router.push('/product/think')"
        >
          查看公司所服务项目<span class="iconfont icon-xiangyou3"></span>
        </div>
      </div>
      <div class="business_content">
        <div
          class="business_item"
          v-for="(item, index) in businessList"
          :key="item.id"
          @mouseenter="item.isShow = false"
          @mouseleave="item.isShow = true"
          @click="$router.push(`${navigateList[index]}`)"
        >
          <img :src="item.business_1" alt="" v-if="item.isShow" />
          <img :src="item.business_active_1" alt="" class="" v-else />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      businessList: [
        {
          id: 1,
          business_1: require('@/assets/images/home/business_1.png'),
          business_active_1: require('@/assets/images/home/business_active_1.png'),
          isShow: true
        },
        {
          id: 2,
          business_1: require('@/assets/images/home/business_2.png'),
          business_active_1: require('@/assets/images/home/business_active_2.png'),
          isShow: true
        },
        {
          id: 3,
          business_1: require('@/assets/images/home/business_3.png'),
          business_active_1: require('@/assets/images/home/business_active_3.png'),
          isShow: true
        }
      ],
      navigateList: ['/product/extension', '/product/think', '/about/team']
    }
  }
}
</script>

<style lang="less" scoped>
@import url('../../../assets/iconfont/index.css');

.home-business-container {
  width: 100%;
  height: 660px;
  position: relative;
}
.business-mode {
  width: 1557px;
  height: 744px;
  border-radius: 5px;
  padding: 88px 85px 101px;
  position: absolute;
  top: 97px;
  left: 50%;
  z-index: 666;
  transform: translateX(-50%);
  background-image: url(~@/assets/images/home/business_bg.png);
  background-size: cover;
}

.business-title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 53px;
  .business-title-left {
    width: 483px;
    height: 107px;
    line-height: 53px;
    font-size: 37px;
    font-weight: 500;
    line-height: 53px;
  }
  .business-title-right {
    width: 190px;
    height: 27px;
    font-size: 19px;
    font-weight: 400;
    line-height: 27px;
    margin-top: 73px;
    cursor: pointer;
    span {
      font-size: 19px;
      font-weight: 400;
    }
  }
}
.business_content {
  display: flex;
  .business_item {
    position: relative;
    width: 459px;
    height: 395px;
    cursor: pointer;
    margin-right: 5px;
  }
  .business_active {
    position: absolute;
    left: 0;
    top: 0;
  }
}
</style>
