<template>
  <div
    class="Tag"
    :style="{
      width: width + 'px',
      height: height + 'px',
      borderColor: borderColor,
      color: color
    }"
  >
    {{ content }}
  </div>
</template>

<script>
export default {
  name: 'QbTag',
  props: {
    content: {
      type: String,
      default: 'Hot course'
    },
    width: {
      type: String,
      default: '137'
    },
    height: {
      type: String,
      default: '31'
    },
    borderColor: {
      type: String,
      default: '#333333'
    },
    color: {
      type: String,
      default: '#333333'
    }
  }
}
</script>

<style lang="less" scoped>
.Tag {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #333333;
  border-radius: 100px;
}
</style>
