import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/views/layout'
import Home from '@/views/home'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '',
        component: Home,
        meta: {
          color: '#ffffff',
          hrShow: true,
          logo: require('@/assets/images/layout/logoWhite.png'),
          title: '上海泉邦网络科技有限公司'
        }
      },
      {
        path: '/home/family',
        component: () => import('@/views/home/family'),
        meta: {
          title: '泉邦标杆案例 - 上海泉邦网络科技有限公司'
        }
      },
      {
        path: '/course/fission',
        component: () => import('@/views/course/fission'),
        meta: {
          title: '公司课程 - 裂变式盈利 - 上海泉邦网络科技有限公司'
        }
      },
      {
        path: '/course/member',
        component: () => import('@/views/course/members'),
        meta: {
          title: '公司课程 - 会员模式 - 上海泉邦网络科技有限公司'
        }
      },
      {
        path: '/course/landing',
        component: () => import('@/views/course/landing'),
        meta: {
          title: '公司课程 - 裂变落地系统 - 上海泉邦网络科技有限公司'
        }
      },
      {
        path: '/course/sale',
        component: () => import('@/views/course/sales'),
        meta: {
          title: '公司课程 - 销讲大师计划 - 上海泉邦网络科技有限公司'
        }
      },
      {
        path: '/product/think',
        component: () => import('@/views/product/think'),
        meta: {
          title: '公司产品 - 智囊团 - 上海泉邦网络科技有限公司'
        }
      },
      {
        path: '/product/empower',
        component: () => import('@/views/product/empower'),
        meta: {
          title: '公司产品 - 赋能计划 - 上海泉邦网络科技有限公司'
        }
      },
      {
        path: '/product/extension',
        component: () => import('@/views/product/extension'),
        meta: {
          title: '公司产品 - 拓站 - 上海泉邦网络科技有限公司'
        }
      },
      {
        path: '/about/contact',
        component: () => import('@/views/about/contact'),
        meta: {
          title: '关于我们 - 联系方式 - 上海泉邦网络科技有限公司'
        }
      },
      {
        path: '/about/application',
        component: () => import('@/views/about/application'),
        meta: {
          title: '关于我们 - 在线报名 - 上海泉邦网络科技有限公司'
        }
      },
      {
        path: '/about/company',
        component: () => import('@/views/about/company'),
        meta: {
          title: '关于我们 - 公司介绍 - 上海泉邦网络科技有限公司'
        }
      },
      {
        path: '/about/job',
        component: () => import('@/views/about/job'),
        meta: {
          title: '关于我们 - 人才招聘 - 上海泉邦网络科技有限公司'
        }
      },
      {
        path: '/case',
        component: () => import('@/views/case'),
        meta: {
          title: '案例展示 - 上海泉邦网络科技有限公司'
        }
      },
      {
        path: '/case/article/:id',
        component: () => import('@/views/case/components/page_article'),
        meta: {
          title: '案例展示 - 文章案例 - 上海泉邦网络科技有限公司'
        }
      },
      {
        path: '/case/video/:id',
        component: () => import('@/views/case/components/page_video'),
        meta: {
          title: '案例展示 - 视频案例 - 上海泉邦网络科技有限公司'
        }
      },
      {
        path: '/about/team',
        component: () => import('@/views/about/team'),
        meta: {
          title: '关于我们 - 公司团队 - 上海泉邦网络科技有限公司'
        }
      },
      {
        path: '/new/news/:id',
        component: () => import('@/views/new/news'),
        meta: {
          title: '新闻咨询 - 公司新闻 - 上海泉邦网络科技有限公司'
        }
      },
      {
        path: '/new/started/:id',
        component: () => import('@/views/new/started'),
        meta: {
          title: '新闻咨询 - 启动大会 - 上海泉邦网络科技有限公司'
        }
      },
      {
        path: '/new/dynamic/:id',
        component: () => import('@/views/new/dynamic'),
        meta: {
          title: '新闻咨询 - 媒体动态 - 上海泉邦网络科技有限公司'
        }
      },
      {
        path: '*/',
        redirect: '/'
      }
    ]
  }
]
const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

export default router
