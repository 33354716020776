<template>
  <div class="home_banner_container">
    <div class="video">
      <video
        src="http://res.quanbangcloud.com/videos/00/0001fd3ddd524e50bcd4951abc3eed27.mp4"
        poster="@/assets/images/video_cover.png"
        autoplay="autoplay"
        muted="muted"
        loop="loop"
      ></video>
    </div>
    <div class="cover">
      <img src="@/assets/images/home/btn-cover.png" alt="" />
    </div>
    <HomeBannerCarousel></HomeBannerCarousel>
    <div class="banner_mask"></div>
  </div>
</template>

<script>
import HomeBannerCarousel from './home-banner-carousel.vue'
export default {
  name: 'HomeBanner',
  components: {
    HomeBannerCarousel
  },
  data() {
    return {}
  }
}
</script>

<style lang="less" scoped>
@media screen and (max-width: 1920px) {
  .home_banner_container {
    height: 1000px;
  }
}

/* 在 600 像素或更小的屏幕上，将背景色设置为橄榄色 */
@media screen and (min-width: 1921px) {
  .home_banner_container {
    height: 1200px;
  }
}
.home_banner_container {
  // height: 1129px;
  // height: 1440px;
  width: 100%;
  position: relative;
  overflow: hidden;
  .cover {
    position: absolute;
    bottom: 0px;
    z-index: 1000;
    width: 100%;
    height: 130px;
  }

  .banner_mask {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: #000000;
    opacity: 0.3;
    z-index: 999;
  }
  .video {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100%;
    video {
      position: absolute;
      right: 0;
      left: 0;
      object-fit: cover !important;
      min-width: 100%;
      min-height: 100%;
      z-index: 998;
    }
  }
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
