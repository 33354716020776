<template>
  <div class="menu_category w">
    <ul>
      <li>
        <a class="anchor" @click="handleGoCourse">金牌课程</a>
        <a class="anchor" @click="handleGoLecturer">导师介绍</a>
        <a class="anchor" @click="handleGoFamily">泉邦标杆案例</a>
      </li>
      <li>
        <router-link to="/course/fission">裂变式盈利</router-link>
        <router-link to="/course/landing">裂变落地系统</router-link>
        <router-link to="/course/member">会员模式</router-link>
        <router-link to="/course/sale">销讲大师计划</router-link>
      </li>
      <li>
        <router-link to="/product/think">智囊团</router-link>
        <router-link to="/product/empower">赋能计划</router-link>
        <router-link to="/product/extension">拓站</router-link>
      </li>
      <li>
        <router-link to="/case">案例展示</router-link>
      </li>
      <li>
        <router-link to="/about/company">公司介绍</router-link>
        <router-link to="/about/team">公司团队</router-link>
        <router-link to="/about/application">在线报名</router-link>
        <router-link to="/about/contact">联系方式</router-link>
        <router-link to="/about/job">人才招聘</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'MenuCategory',
  methods: {
    async handleGoCourse() {
      if (this.$route.fullPath === '/') {
        document.querySelector('#anchor-course').scrollIntoView()
      } else {
        await this.$router.push('/')
        document.querySelector('#anchor-course').scrollIntoView()
      }
    },
    async handleGoLecturer() {
      if (this.$route.fullPath === '/') {
        document.querySelector('#anchor-lecturer').scrollIntoView()
      } else {
        await this.$router.push('/')
        document.querySelector('#anchor-lecturer').scrollIntoView()
      }
    },
    async handleGoFamily() {
      if (this.$route.fullPath === '/') {
        document.querySelector('#anchor-family').scrollIntoView()
      } else {
        await this.$router.push('/')
        document.querySelector('#anchor-family').scrollIntoView()
      }
    }
  }
}
</script>

<style lang="less" scoped>
.menu_category {
  height: 596px;
  // width: 1920px;
  // padding-left: 580px;
  padding-top: 157px;
  margin: 0 auto;
  overflow: hidden;
}
ul {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  padding-left: 439px;
}

li {
  width: 268px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 19px;
  font-weight: 500;
  color: #ffffff;
  line-height: 27px;
  height: 100%;
  a {
    margin-bottom: 23px;
    display: block;
    width: 268px;
    text-align: center;
    opacity: 0.7;
    color: #ffffff;
    cursor: pointer;
    &:hover {
      color: #e7fdff;
      opacity: 1;
    }
  }
}
</style>
