<template>
  <div class="container">
    <div class="footer w">
      <div class="footer_left">
        <div class="footer_left_title">泉邦集团</div>
        <ul class="footer_left_contact_list">
          <li>
            <img class="img" src="@/assets/images/layout/wxlogo.png" />
            <img class="imgSelect" src="@/assets/images/layout/wxlogo1.png" />
            <div class="qrCord">
              <img src="@/assets/images/layout/qr-company.png" alt="" />
            </div>
          </li>
          <li>
            <img class="img" src="@/assets/images/layout/wxlogo.png" alt="" />
            <img
              class="imgSelect"
              src="@/assets/images/layout/wxlogo1.png"
              alt=""
            />
            <div class="qrCord">
              <img src="@/assets/images/layout/qr-advisory.png" alt="" />
            </div>
          </li>
          <li>
            <img class="img" src="@/assets/images/layout/kflogo.png" alt="" />
            <img
              class="imgSelect"
              src="@/assets/images/layout/kflogo1.png"
              alt=""
            />
          </li>
        </ul>
        <div class="contact">
          <p>地址 : 上海嘉定区中暨大厦3F</p>
          <p>电话 : 4009997507</p>
        </div>
      </div>
      <div class="footer_right">
        <ul class="menu_list">
          <li>
            <dl>
              <dt>
                <router-link class="link" to="/">
                  首页
                </router-link>
              </dt>
              <dd>
                <router-link class="link subitem" to="/course/fission">
                  泉邦金牌课程
                </router-link>
              </dd>
              <dd>
                <router-link class="link subitem" to="/about/team">
                  导师介绍
                </router-link>
              </dd>
              <dd>
                <router-link class="link subitem" to="/case">
                  泉邦标杆案例
                </router-link>
              </dd>
            </dl>
          </li>
          <li>
            <dl>
              <dt>
                <router-link class="link" to="/course/fission">
                  公司课程
                </router-link>
              </dt>
              <dd>
                <router-link class="link subitem" to="/course/fission">
                  裂变式盈利
                </router-link>
              </dd>
              <dd>
                <router-link class="link subitem" to="/course/sale">
                  销讲大师计划
                </router-link>
              </dd>
              <dd>
                <router-link class="link subitem" to="/course/member">
                  会员模式
                </router-link>
              </dd>
              <dd>
                <router-link class="link subitem" to="/course/landing">
                  裂变落地系统
                </router-link>
              </dd>
            </dl>
          </li>
          <li>
            <dl>
              <dt>
                <router-link class="link" to="/product/think">
                  产品模型
                </router-link>
              </dt>
              <dd>
                <router-link class="link subitem" to="/product/think">
                  智囊团
                </router-link>
              </dd>
              <dd>
                <router-link class="link subitem" to="/product/empower">
                  赋能计划
                </router-link>
              </dd>
              <dd>
                <router-link class="link subitem" to="/product/extension">
                  拓站
                </router-link>
              </dd>
              <dd>
                <router-link class="link subitem" to="/case">
                  案例展示
                </router-link>
              </dd>
            </dl>
          </li>
          <li>
            <dl>
              <dt>
                <router-link class="link" to="/">关于我们</router-link>
              </dt>
              <dd>
                <router-link class="link subitem" to="/about/company">
                  公司介绍
                </router-link>
              </dd>
              <dd>
                <router-link class="link subitem" to="/about/team">
                  公司团队
                </router-link>
              </dd>
              <dd>
                <router-link class="link subitem" to="/about/application">
                  在线报名
                </router-link>
              </dd>
              <dd>
                <router-link class="link subitem" to="/about/contact">
                  联系方式
                </router-link>
              </dd>
              <dd>
                <router-link class="link subitem" to="/about/job">
                  人才招聘
                </router-link>
              </dd>
            </dl>
          </li>
        </ul>
      </div>
    </div>
    <div class="record">
      Copyright ©2021上海泉邦网络科技有限公司 &nbsp;&nbsp;&nbsp;&nbsp;
      <a
        rel="nofollow"
        href="https://beian.miit.gov.cn/#/Integrated/index"
        target="_blank"
      >
        <span>沪ICP备17000895号-6</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  background-color: #f9f9f9;
  border-bottom: 21px solid #1a1b21;
}
.footer {
  height: 564px;
  // border-bottom: 21px solid #1a1b21;
  display: flex;
  justify-content: space-between;
  background-color: #f9f9f9;
}
.footer_left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 71px;
  margin-left: 181px;
  .footer_left_title {
    font-size: 24px;
    font-family: Helvetica;
    color: #0a49dd;
    margin-bottom: 85px;
    font-weight: 700;
  }
  .footer_left_version {
    font-size: 16px;
    font-family: Helvetica;
    line-height: 19px;
  }
  .footer_left_contact_list {
    display: flex;
    li {
      position: relative;
      width: 39px;
      height: 39px;
      border-radius: 50%;
      margin-right: 21px;
      margin-bottom: 75px;
      cursor: pointer;
      &:hover .qrCord {
        display: block;
      }
      &:hover .img {
        display: none;
      }
      &:hover .imgSelect {
        display: block;
      }
      .qrCord {
        display: none;
        width: 126px;
        position: absolute;
        top: 40px;
        left: -45px;
      }
      .imgSelect {
        display: none;
      }
    }
    // li:nth-of-type(1) {
    //   background: url('~@/assets/images/layout/知乎logo.png') no-repeat center
    //     center;
    //   background-size: 39px 39px;
    // }
    // li:nth-of-type(2) {
    //   background: url('~@/assets/images/layout/微信logo.png') no-repeat center
    //     center;
    //   background-size: 39px 39px;
    // }
    // li:nth-of-type(3) {
    //   background: url('~@/assets/images/layout/客服logo.png') no-repeat center
    //     center;
    //   background-size: 39px 39px;
    // }
  }
}
.menu_list {
  display: flex;
  justify-content: space-between;
  margin-top: 85px;
  margin-right: 290px;
  li {
    margin-left: 155px;
    dt {
      font-size: 21px;
      font-weight: 700;
      line-height: 29px;
      margin-bottom: 32px;
    }
    dd {
      font-size: 19px;
      font-weight: 500;
      line-height: 27px;
      margin-bottom: 37px;
      a:hover {
        color: #333;
      }
      .subitem {
        opacity: 0.6;
      }
    }
  }
}

.record {
  line-height: 50px;
  background-color: #e6e6e6;
  text-align: center;
  color: #666;
  a {
    color: #666;
  }
  a:hover {
    color: #666;
  }
}
</style>
