import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/styles/base.less'
import plugin from '@/components'
import {
  Radio,
  RadioGroup,
  CarouselItem,
  Carousel,
  Form,
  FormItem,
  Tabs,
  TabPane,
  Input,
  Button,
  Checkbox,
  CheckboxGroup
} from 'element-ui'
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Input)
Vue.use(Button)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)

Vue.use(plugin)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
