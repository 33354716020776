<template>
  <div class="qb_schedule_container">
    <div class="sales_schedule w">
      <div class="img">
        <img :src="schedule" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'QbSchedule',
  props: {
    schedule: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="less" scoped>
.sales_schedule {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 913px;
  background-color: #f7f9fd;
  .img {
    width: 1555px;
    height: 743px;
  }
}
</style>
