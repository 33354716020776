export default {
  install(Vue) {
    const ctx = require.context('./', false, /\.vue$/)
    ctx.keys().forEach(item => {
      // console.log(ctx(item).default.name)
      const component = ctx(item).default
      Vue.component(component.name, component)
    })
  }
}
