<template>
  <div class="qb_cover_container">
    <div class="sales_cover w">
      <img :src="src" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'QbCover',
  props: {
    src: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="less" scoped>
.sales_cover {
  width: 100%;
  height: 821px;
}
</style>
