<template>
  <div class="tapnav_container">
    <div class="content">
      <div class="warp">
        <div class="logo" @click="$router.push('/')">
          <img :src="logo" alt="上海泉邦网络科技有限公司" />
        </div>
        <div
          class="menu-list"
          @mouseenter="handleChangeMnue(true)"
          @mouseleave="handleChangeMnue(false)"
        >
          <div class="menu-item">
            <router-link to="/" :style="{ color }">首页</router-link>
          </div>
          <div class="menu-item">
            <router-link to="/course/fission" :style="{ color }">
              课程培训
            </router-link>
          </div>
          <div class="menu-item">
            <router-link to="/product/think" :style="{ color }"
              >咨询服务</router-link
            >
          </div>
          <div class="menu-item">
            <router-link to="/case" :style="{ color }">案例展示</router-link>
          </div>
          <div class="menu-item">
            <router-link to="/about/company" :style="{ color }"
              >关于我们</router-link
            >
          </div>
        </div>
      </div>
    </div>
    <!-- <div
      class="menu_category_list"
      @mouseenter="handleAddContainerColor"
      @mouseleave="handleRemoveContainerColor"
      :class="{ show: active }"
    >
      <MenuCategoryList></MenuCategoryList>
    </div> -->
    <div class="hr" v-if="hrShow"></div>
  </div>
</template>

<script>
// import MenuCategoryList from '../menu-category-list'
export default {
  name: 'TopNav',
  data() {
    return {}
  },
  components: {
    // MenuCategoryList
  },
  props: {
    color: {
      type: String,
      default: '#333333'
    },
    logo: {
      type: String,
      default: require('@/assets/images/layout/logoBlue.png')
    },
    hrShow: {
      type: Boolean,
      default: false
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleChangeMnue(flag) {
      this.$emit('handleChangeMnue', flag)
    }
  }
}
</script>

<style lang="less" scoped>
.tapnav_container {
  position: absolute;
  top: 0;
  z-index: 1002;
  width: 100%;
}

.warp {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 1920px;
  height: 113px;
  padding: 0px 0px 0px 68px;
  margin: 0 auto;
}

.logo {
  width: 160px;
  height: 51px;
  // margin-right: 353px;
  cursor: pointer;
}

.menu-list {
  width: 1692px;
  float: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .menu-item {
    width: 268px;
    height: 113px;
    line-height: 113px;
    text-align: center;
    font-size: 21px;
    font-weight: 500;
  }
}

// .menu_category_list {
//   position: absolute;
//   top: 113px;
//   height: 483px;
//   width: 100%;
//   background-color: #000834;
//   display: none;
//   background-color: rgba(0, 0, 52, 0.2);
// }

// .show {
//   display: block;
//   animation: topin 0.6s;
// }

// @keyframes topin {
//   0% {
//     transform: translateY(-10px);
//   }
//   100% {
//     transform: translateY(0px);
//   }
// }

.hr {
  width: 100%;
  border-bottom: 1px solid #ccc;
  padding: 0px 315px 0px 68px;
}
</style>
