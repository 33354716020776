<template>
  <div class="container w">
    <div class="application_form">
      <div class="application_form_title">报名参加</div>
      <div class="application_form_content">
        <el-form
          :model="formData"
          :rules="rules"
          ref="ruleForm"
          class="demo-ruleForm"
          size="medium"
        >
          <el-form-item label="您要参加的场次：" prop="session" class="session">
            <el-radio-group v-model="formData.session">
              <el-radio
                label="2021年10月12日——14日·上海站·《裂变式盈利》"
              ></el-radio>
              <el-radio
                label="2021年10月12日——14日·成都站·《裂变式盈利》"
              ></el-radio>
              <el-radio
                label="2021年10月12日——14日·云南站·《裂变式盈利》"
              ></el-radio>
            </el-radio-group>
          </el-form-item>
          <!-- 姓名 -->
          <el-form-item prop="name" label-width="0px">
            <el-input
              type="text"
              v-model="formData.name"
              autocomplete="off"
              class="el-input"
              placeholder="姓名"
            ></el-input>
          </el-form-item>
          <!-- 行业 -->
          <el-form-item prop="industry" label-width="0px">
            <el-input
              type="text"
              v-model="formData.industry"
              autocomplete="off"
              class="el-input"
              placeholder="职位"
            ></el-input>
          </el-form-item>
          <!-- 手机号 -->
          <el-form-item prop="phone" label-width="0px" class="mt">
            <el-input
              type="text"
              v-model="formData.phone"
              autocomplete="off"
              class="el-input"
              placeholder="手机号"
            ></el-input>
          </el-form-item>
          <!-- 微信号 -->
          <el-form-item prop="wxNumber" label-width="0px" class="mt">
            <el-input
              type="text"
              v-model="formData.wxNumber"
              autocomplete="off"
              class="el-input"
              placeholder="推荐人"
            ></el-input>
          </el-form-item>
          <!-- 城市地址 -->
          <el-form-item prop="cityAdress" label-width="0px" class="mt">
            <el-input
              type="text"
              v-model="formData.cityAdress"
              autocomplete="off"
              class="el-input last"
              placeholder="公司名称"
            ></el-input>
          </el-form-item>
          <!-- 同意 -->
          <el-form-item prop="agree" class="el-checkbox">
            <el-checkbox-group v-model="formData.agree">
              <el-checkbox></el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <!-- 立即创建 -->
          <el-form-item>
            <el-button class="submit_button" @click="onSubmit"
              >立即参与课程</el-button
            >
          </el-form-item>
          <div class="comfirm">
            <span @click="handleChangeCheck" class="cursor"
              >确认同意泉邦集团</span
            ><a href="javascript:;">保密协议</a>
          </div>
        </el-form>
      </div>
    </div>
    <div class="application_suggestion">
      <div class="application_suggestion_title">泉邦给企业家的一些建议</div>
      <div class="application_suggestion_content">
        在这个机遇与危机共存的商业时代，能否找到完整清晰的盈利
        <span>模式，决定了企业发展的高度。</span>
      </div>
      <div class="person_avatar">
        <div class="avatar">
          <img src="@/assets/images/layout/mbc2.png" alt="" />
        </div>
        <span>马煜超</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'QbApplication',
  data() {
    return {
      formData: {
        name: '',
        industry: '',
        phone: '',
        wxNumber: '',
        cityAdress: '',
        agree: true
      },
      rules: {
        name: [
          { required: true, message: '', trigger: 'blur' },
          { min: 3, max: 5, message: '', trigger: 'blur' }
        ],
        industry: [{ required: true, message: '', trigger: 'blur' }],
        phone: [{ required: true, message: '', trigger: 'blur' }],
        wxNumber: [{ required: true, message: '', trigger: 'blur' }],
        cityAdress: [{ required: true, message: '', trigger: 'blur' }],
        agree: [{ required: true, message: '', trigger: 'blur' }]
      },
      isChecked: false
    }
  },
  methods: {
    async onSubmit() {
      let msg = ''
      await this.$refs.ruleForm.validate((valid, obj) => {
        Object.values(obj).forEach(i => (msg += i[0].field + '未填写' + ';'))
      })
      alert(msg)
      this.$refs.ruleForm.resetFields()
    },
    handleChangeCheck() {
      this.formData.agree = !this.formData.agree
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  height: 749px;
  // background-color: #333;
  display: flex;
  position: relative;
}

.application_form {
  background-color: #1a1b21;
  padding-top: 73px;
  padding-left: 181px;
  width: 981px;
  position: relative;
  .application_form_title {
    font-size: 32px;
    font-weight: 600;
    color: #ffffff;
    line-height: 44px;
  }
}
::v-deep .demo-ruleForm {
  display: flex;
  flex-wrap: wrap;
  .session {
    margin-top: 31px;
    .el-form-item__label {
      font-size: 21px;
      font-weight: 500;
      color: #8c8c8f;
      line-height: 29px;
      margin-bottom: 12px;
    }
    .el-radio__label {
      font-size: 21px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #8c8c8f;
      line-height: 29px;
    }
    .el-radio__inner {
      width: 20px;
      height: 20px;
      border: 1px solid #979797;
      background: #1a1b21;
      margin-bottom: 8px;
    }
    .el-radio__inner::after {
      width: 16px;
      height: 16px;
      background: #ffffff;
    }
  }
  .mt {
    margin-top: 10px;
  }
  .el-input {
    width: 357px;
    font-weight: 500;
    color: #ffffff;
    background-color: #1a1b21;
    margin-right: 43px;

    .el-input__inner {
      padding: 0;
      background-color: #1a1b21;
      border: 0px;
      border-bottom: 3px solid #d8d8d8;
      border-radius: 0;
      font-size: 21px;
      text-indent: 0rem;
      height: 40px;
      padding-bottom: 15px;
      color: #fff;
    }
    .el-checkbox {
      flex-basis: 100%;
      margin-top: 10px;
      margin-bottom: 20px;
    }
  }
  .last {
    width: 757px;
    flex-basis: 100%;
  }

  .submit_button {
    width: 227px;
    height: 67px;
    background: #ffffff;
    border-radius: 33px;
    font-size: 19px;
    font-weight: 500;
    color: #1a1b21;
    line-height: 27px;
  }
}
.el-checkbox {
  flex-basis: 100%;
  margin-top: 10px;
}
::v-deep .el-checkbox__inner {
  width: 24px;
  height: 24px;
  background-color: #272a32 !important;
  border-color: #979797 !important;
  &::after {
    height: 18px;
    width: 7px;
    left: 7px;
    top: -1px;
    border: 2px solid #fff;
    border-left: 0;
    border-top: 0;
  }
}
.application_suggestion {
  width: 939px;
  background-color: #272a32;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #ffffff;
  .application_suggestion_title {
    margin-top: 108px;
    font-size: 32px;
    font-weight: 600;
  }
  .application_suggestion_content {
    width: 667px;
    height: 107px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 64px;
    font-size: 24px;
    font-weight: 300;
    line-height: 48px;
  }
  .person_avatar {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 64px;
    font-weight: 600;
    font-size: 24px;
    .avatar {
      width: 91px;
      height: 91px;
      margin: 32px 0;
    }
  }
}

.comfirm {
  position: absolute;
  top: 560px;
  left: 220px;
  font-size: 19px;
  font-weight: 500;
  color: #666;
  line-height: 27px;
  .cursor {
    cursor: pointer;
  }
  a {
    text-decoration: underline;
  }
}
</style>
