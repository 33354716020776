<template>
  <div class="banner_carousel">
    <el-carousel
      indicator-position="outside"
      height="1129px"
      ref="bannerCarousel"
      arrow="never"
    >
      <el-carousel-item
        v-for="(item, index) in bannerCourseList"
        :key="item.id"
      >
        <div class="banner_carousel_content">
          <div class="remark" v-html="item.remake"></div>
          <div class="banner_carousel_content_name">
            <div v-if="item.title === '智囊团'">
              智囊团<i class="iconfont icon-Rbiao R"></i>&nbsp;&nbsp;陪跑计划
            </div>
            <div v-else>
              {{ item.title }}
            </div>
          </div>
          <div class="banner_carousel_content_remake">
            {{ item.introduction }}
          </div>
          <div class="banner_carousel_content_money">
            RMB {{ item.money }} 元
          </div>
          <div class="banner_carousel_content_button" @click="goPage(index)">
            立即参与
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
    <div class="banner_course_blur"></div>
    <div class="banner_course_list">
      <div
        class="banner_course_item"
        v-for="(item, index) in bannerCourseList"
        :key="item.id"
        @click="handleChangeBannerCarousel(index)"
      >
        <div v-if="item.title !== '智囊团'">
          <span
            v-if="index === 0"
            class="iconfont icon-huo"
            style="font-size:21px;margin-right:5px"
          ></span>
          {{ item.title }}
        </div>
        <div class="think">
          智囊团<i class="iconfont icon-Rbiao R"></i>&nbsp;&nbsp;&nbsp;陪跑计划
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      bannerCourseList: [
        {
          id: 1,
          title: '裂变式盈利',
          introduction: '一对多认知赋能 企业家必修课程',
          money: '1,980',
          remake: `<p>《裂变式盈利》就是让企业在原有产品和项目的基础上，结合当下的市场环境，嫁接一套新的商业模式和系统，转变经营思维，改变经营方式。</p>
以专业的角度，分析不同企业所遇到的问题，以助力企业更好地发展。通过裂变思维创造更多的盈利，最终实现企业的人才裂变、渠道裂变、时间裂变。`
        },
        {
          id: 2,
          title: '会员模式',
          introduction: `底层设计 迅速吸金
                          简单粗暴 行之有效
          `,
          money: '12,800',
          remake: `<p>如何最大化的保有老客户，转化新客户？如何精准分析客户潜在消费机会？如何挖掘客户持续消费力，培育客户终身价值？</p>
针对于这些企业难题，需要有一套完善的会员模式来实现。那么，会员模式如何建立？中小企业如何打造独具特色的会员模式，实现企业快速发展？这一切，还需要《会员模式》课程！`
        },
        {
          id: 3,
          title: '裂变落地系统',
          introduction: '一对一方案辅导 小班制烧脑干货',
          money: '39,800',
          remake: `<p>随着企业的经营渐入佳境不断扩张，相信越来越多的企业经营者都在选择直营模式还是加盟模式产生纠结。</p><br/>
就拿直营与加盟模式用处最广的餐饮行业，各类巨头的选择也各不相同：直营、加盟、直营与加盟结合，可见，单是直营模式与加盟模式的组合就值得一家企业反复考量。更何况这些模式背后更为复杂繁琐的体系，也将不断消磨企业经营者的精力。`
        },
        {
          id: 4,
          title: '赋能计划',
          introduction: '实操密训系统跟进 轻松实现顾客裂变',
          money: '128,000',
          remake: `<p>将组织8-10人实操私房密训，从方案制定到方案审核，最后到最终方案落地，给你一个最合适的创业思路。</p><br/>
线上系统一对一跟进，定制化合作协议方案文案参照库`
        },
        {
          id: 5,
          title: '智囊团',
          introduction: '选对正确赛道 全程加速陪跑 智囊倾力相助 迅速落地见效',
          money: '338,000',
          remake: `<p>站在资本角度，架构企业未来，智囊团陪跑计划按需定制给出一套系统的完善的具有裂变效应的整体方案，分化层级利润，加快推动速度。</p>
结缘泉邦，一次投资终身相伴。在一年半的持续跟踪式服务后，咨询项目组仍将不定期追踪和回访协议期内所制定的方案落地执行情况，为客户提供有效支持。
`
        }
      ],
      urlList: [
        '/course/fission',
        '/course/member',
        '/course/landing',
        '/product/empower',
        '/product/think'
      ]
    }
  },
  methods: {
    handleChangeBannerCarousel(index) {
      this.$refs.bannerCarousel.setActiveItem(index)
    },
    goPage(index) {
      this.$router.push(this.urlList[index])
    }
  }
}
</script>

<style lang="less" scoped>
.banner_carousel {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
}
::v-deep .el-carousel__indicators {
  display: none;
}
.banner_carousel_content {
  color: #ffffff;
  margin-top: 307px;
  margin-left: 183px;
  position: absolute;
  z-index: 1000;
  opacity: 1;
  .remark {
    width: 1287px;
    font-size: 24px;
    font-weight: 400;
    color: #ffffff;
    line-height: 33px;
    margin-bottom: 80px;
  }
  .banner_carousel_content_time {
    font-size: 19px;
    font-weight: 500;
  }
  .banner_carousel_content_name {
    position: relative;
    font-size: 64px;
    font-weight: 600;
    margin-top: 15px;
    i {
      position: absolute;
      left: 194px;
      top: 4px;
      font-size: 40px;
    }
  }
  .banner_carousel_content_remake {
    font-size: 24px;
    font-weight: 600;
    color: #ffffff;
    line-height: 33px;
  }
  .banner_carousel_content_money {
    font-size: 24px;
    font-weight: 400;
    color: #ffffff;
    line-height: 33px;
    margin-top: 32px;
  }
  .banner_carousel_content_button {
    width: 224px;
    height: 48px;
    line-height: 48px;
    text-align: center;
    background: #1651dd;
    border-radius: 24px;
    font-size: 24px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    margin-top: 39px;
    cursor: pointer;
  }
}

.banner_course_blur {
  position: absolute;
  z-index: 998;
  bottom: 0;
  height: 127px;
  width: 100%;
  background-color: #000000;
  opacity: 0.1;
  cursor: pointer;
}
.banner_course_list {
  display: flex;
  position: absolute;
  z-index: 1000;
  bottom: 0;
  height: 130px;
  line-height: 130px;
  width: 100%;
  cursor: pointer;
  .banner_course_item {
    flex: 1;
    text-align: center;
    font-size: 21px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    // text-shadow: 0px 3px 5px rgba(255, 255, 255, 0.5);
    text-shadow: 0px 3px 5px rgba(255, 255, 255, 0);
    cursor: pointer;
    border-right: 3px solid rgba(255, 255, 255, 0.3);
    border-top: 3px solid rgba(255, 255, 255, 0.3);
    &:hover {
      opacity: 0.6;
    }
  }
  .think {
    position: relative;
    i {
      position: absolute;
      bottom: 5px;
      font-size: 18px;
    }
  }
}
.el-carousel__item h3 {
  // color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}
</style>
