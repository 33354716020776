<template>
  <div class="home-course-container">
    <div class="course-title">
      泉邦金牌课程
    </div>
    <div class="indicator">
      <div class="previous_page" @click="handlePreviousChange">
        <span class="iconfont icon-xiangzuo3 before"></span>上一页
      </div>
      <div class="indicator_list">
        <div
          class="indicator_item"
          v-for="(item, index) in carouselList.length"
          :key="index"
          :class="{ isActive: active === index }"
          @mouseenter="handleChangeIndicator(index)"
        ></div>
      </div>
      <div class="next_page" @click="handleNextChange">
        下一页<span class="iconfont icon-xiangyou3 next"></span>
      </div>
    </div>
    <div class="course-carousel">
      <el-carousel
        :interval="2000"
        arrow="never"
        indicator-position="outside"
        height="778px"
        ref="course_carousel"
        @change="changeCarousel"
      >
        <el-carousel-item v-for="item in carouselList" :key="item.id">
          <HomeCourseItem :item="item"></HomeCourseItem>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
import HomeCourseItem from './home-course-item.vue'
export default {
  name: 'HomeCourse',
  components: {
    HomeCourseItem
  },
  data() {
    return {
      active: 1,
      flag: true,
      carouselList: [
        {
          id: 1,
          title_left: '裂变式盈利',
          title_right: '会员模式',
          picture_left: require('@/assets/images/home/course_fission.png'),
          picture_right: require('@/assets/images/home/course_member.png'),
          navigator_left: '/course/fission',
          navigator_right: '/course/member'
        },
        {
          id: 2,
          title_left: '销讲大师计划',
          title_right: '赋能计划',
          picture_left: require('@/assets/images/home/course_sale.png'),
          picture_right: require('@/assets/images/home/course_extension.png'),
          navigator_left: '/course/sale',
          navigator_right: '/product/empower'
        },
        {
          id: 3,
          title_left: '智囊团',
          title_right: '裂变落地系统',
          picture_left: require('@/assets/images/home/course_think.png'),
          picture_right: require('@/assets/images/home/course_landing.png'),
          navigator_left: '/product/think',
          navigator_right: '/course/landing'
        }
      ]
      // carouselList: [
      //   {
      //     id: 1,
      //     cover_image_left: require('@/assets/images/home/fission_cover.png'),
      //     title_left: '裂变式盈利课程',
      //     content_left:
      //       '通过三天两夜的学习，理顺裂变逻辑，贯彻裂变思维，为自身企业进阶助力。',
      //     navigation_left: '/course/fission',
      //     cover_image_top: require('@/assets/images/home/memeber_top.png'),
      //     title_top: '会员模式',
      //     content_top:
      //       ' 天下资源并非我所有，皆可为我所用<br />拓、留、锁、升系统模式<br />让客户与您终身捆绑！',
      //     navigation_top: '/course/member',
      //     cover_image_bottom: require('@/assets/images/home/memeber_bottom.png'),
      //     title_bottom: '会员模式',
      //     content_bottom:
      //       '通过三天两夜的学习，理顺裂变逻辑，贯彻裂变思维，为自身企业进阶助力。',
      //     navigation_bottom: '/course/member'
      //   },
      //   {
      //     id: 2,
      //     cover_image_left: require('@/assets/images/home/extension_cover.png'),
      //     title_left: '泉邦拓站',
      //     content_left:
      //       '解决连锁加盟管理难题；帮助店铺实现渠道多元化、数据可视化、盈利最大化。',
      //     navigation_left: '/product/extension',
      //     cover_image_top: require('@/assets/images/home/think_top.png'),
      //     title_top: '智囊团',
      //     content_top:
      //       '给出一套系统的完善的具有裂变效应的整体方案，分化层级利润，加快推动速度。',
      //     navigation_top: '/product/think',
      //     cover_image_bottom: require('@/assets/images/home/think_bottom.png'),
      //     title_bottom: '智囊团',
      //     content_bottom:
      //       '赠送20个《裂变式盈利》名额，以现场说法，以真实打动，协助你成交。',
      //     navigation_bottom: '/product/think'
      //   },
      //   {
      //     id: 3,
      //     cover_image_left: require('@/assets/images/home/sale_cover.png'),
      //     title_left: '销讲大师计划',
      //     content_left:
      //       '让你拥有公众演说能力和超强销售能力，将原本的招商能力放大数十倍。',
      //     navigation_left: '/course/sale',
      //     cover_image_top: require('@/assets/images/home/land_top.png'),
      //     title_top: '裂变落地系统',
      //     content_top:
      //       '转变企业经营者经营思维，改变经营方式，实现裂变，创造更多盈利，最终实现企业的人才裂变，渠道裂变，时间裂变',
      //     navigation_top: '/course/landing',
      //     cover_image_bottom: require('@/assets/images/home/empower_bottom.png'),
      //     title_bottom: '赋能计划',
      //     content_bottom:
      //       '将组织8-10人实操私房密训，从方案制定到方案审核，最后到最终方案落地，给你一个最合适的创业思路。',
      //     navigation_bottom: '/product/empower'
      //   }
      // ]
    }
  },
  methods: {
    handleChangeIndicator(index) {
      this.active = index
      this.$refs.course_carousel.setActiveItem(index)
    },
    changeCarousel(e) {
      this.active = e
    },
    handlePreviousChange() {
      // 做一个简单的节流
      if (!this.flag) {
        return
      }
      this.$refs.course_carousel.prev()
      this.flag = false
      setTimeout(() => {
        this.flag = true
      }, 500)
    },
    handleNextChange() {
      // 做一个简单的节流
      if (!this.flag) {
        return
      }
      this.$refs.course_carousel.next()
      this.flag = false
      setTimeout(() => {
        this.flag = true
      }, 500)
    }
  }
}
</script>

<style lang="less" scoped>
.home-course-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 1293px;
  background-color: #00123f;
}
.course-title {
  font-size: 37px;
  font-weight: 500;
  color: #ffffff;
  margin-top: 100px;
  margin-bottom: 44px;
}
.indicator {
  width: 1556px;
  display: flex;
  color: #fff;
  justify-content: space-between;
  div {
    font-size: 20px;
    font-weight: 400;
    color: #ffffff;
    height: 27px;
    line-height: 27px;
    // 文字不被选中
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;

    span {
      font-size: 20px;
      color: #fff;
    }
    .before {
      margin-right: 15px;
    }
    .next {
      margin-left: 15px;
    }
  }
  .indicator_list {
    display: flex;
    .isActive {
      background: #ffffff !important;
      opacity: 1 !important;
    }
    .indicator_item {
      width: 32px;
      height: 5px;
      background: #e0dfdf;
      margin: 0px 8px;
      border-radius: 3px;
      opacity: 0.4;
      cursor: pointer;
    }
  }
}
.course-carousel {
  width: 1556px;
  height: 778px;
  margin-top: 25px;

  /deep/ .el-carousel__indicators {
    display: none;
  }
}
</style>
