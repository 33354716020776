<template>
  <div class="title_container">
    <div class="title w">
      <div v-html="title"></div>
      <div :class="{ active: complementChangeStyle }">
        {{ complement }}
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'QbTitle',
  props: {
    title: {
      type: String,
      required: true
    },
    complement: {
      type: String,
      required: true
    },
    complementChangeStyle: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="less" scoped>
.title_container {
  position: relative;
}
.title {
  height: 564px;
  background-color: #f9f9f9;
  border-top: 1px solid #f9f9f9;
  div:nth-child(1) {
    height: 67px;
    line-height: 67px;
    font-size: 48px;
    font-weight: 600;
    margin-left: 315px;
    margin-top: 212px;
  }
  div:nth-child(2) {
    height: 67px;
    line-height: 67px;
    font-size: 48px;
    font-weight: 600;
    margin-left: 315px;
    &.active {
      height: 39px;
      font-size: 28px;
      font-weight: 400;
      line-height: 39px;
      margin-top: 11px;
    }
  }
}
</style>
