<template>
  <!-- <div class="goldcourse">
    <div class="profit_oriented" @click="$router.push(course.navigation_left)">
      <div class="profit_oriented_image">
        <img :src="course.cover_image_left" alt="" />
      </div>
      <div class="profit_oriented_content">
        <div class="profit_oriented_content_left">
          <div class="profit_oriented_content_left_tag">
            <div
              class="tag_container"
              :style="{
                width: '137px',
                height: '39px'
              }"
            >
              hot course
            </div>
          </div>
          <div class="profit_oriented_content_left_coursesName">
            {{ course.title_left }}
          </div>
        </div>
        <div class="profit_oriented_content_right">
          {{ course.content_left }}
        </div>
      </div>
    </div>
    <div class="membership">
      <div class="member_top" @click="$router.push(course.navigation_top)">
        <div class="box1">
          <div class="home-course-content-container">
            <div class="tag">
              <div
                class="tag_container"
                :style="{
                  width: '137px',
                  height: '39px'
                }"
              >
                hot course
              </div>
            </div>
            <div class="title">
              {{ course.title_top }}
            </div>
            <div class="content" v-html="course.content_top"></div>
            <div class="triangle right"></div>
          </div>
        </div>
        <div class="box2">
          <img class="course2Img" :src="course.cover_image_top" alt="" />
        </div>
      </div>
      <div
        class="member_bottom"
        @click="$router.push(course.navigation_bottom)"
      >
        <div class="box3">
          <img class="course3Img" :src="course.cover_image_bottom" alt="" />
        </div>
        <div class="box4">
          <div class="home-course-content-container">
            <div class="tag">
              <div
                class="tag_container"
                :style="{
                  width: '137px',
                  height: '39px'
                }"
              >
                hot course
              </div>
            </div>
            <div class="title">
              {{ course.title_bottom }}
            </div>
            <div class="content">
              {{ course.content_bottom }}
            </div>
            <div class="triangle left"></div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <div class="course_item_container">
    <div class="course_item_left" @click="$router.push(item.navigator_left)">
      <div class="cover">
        <img :src="item.picture_left" alt="" />
      </div>
      <p class="title">《{{ item.title_left }}》</p>
    </div>
    <div class="course_item_right" @click="$router.push(item.navigator_right)">
      <p class="title">《{{ item.title_right }}》</p>
      <div class="cover">
        <img :src="item.picture_right" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    // course: {
    //   type: Object,
    //   required: true
    // }
    item: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="less" scoped>
.course_item_container {
  display: flex;
  width: 1560px;
  height: 780px;
  cursor: pointer;
  .title {
    height: 164px;
    line-height: 164px;
    text-align: center;
    font-size: 69px;
    font-family: Zhenyan;
    font-weight: 400;
    color: #333333;
    background-color: #fff;
  }
  img {
    display: block;
  }
  .course_item_left {
    width: 780px;
    &:hover {
      .title {
        background-color: #1651dd;
        color: #fff;
      }
    }
  }
  .course_item_right {
    width: 780px;
    &:hover {
      .title {
        background-color: #1651dd;
        color: #fff;
      }
    }
  }
}
@font-face {
  font-family: 'Zhenyan';
  src: url('~@/assets/fonts/锐字真言体.ttf');
}
// .goldcourse {
//   width: 100%;
//   height: 100%;
//   display: flex;
//   background-color: #fff;
//   .tag_container {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     border: 1px solid #333333;
//     border-radius: 100px;
//   }
// }
// .profit_oriented {
//   flex: 1;
//   cursor: pointer;
//   &:hover .profit_oriented_content {
//     background-color: #1651dd;
//     color: #ffffff;
//   }
//   &:hover .tag_container {
//     border: 1px solid #ffffff;
//   }
// }

// .active {
//   background-color: #1651dd;
//   color: #fff;
// }
// .profit_oriented_image {
//   width: 778.008px;
//   height: 616px;
//   img {
//     display: block;
//     width: 100%;
//     height: 100%;
//     object-fit: fill;
//   }
// }
// .profit_oriented_content {
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   height: 163px;
//   .profit_oriented_content_left {
//     margin-left: 64px;
//     position: relative;
//     width: 50%;
//     height: 100%;
//     .profit_oriented_content_left_tag {
//       margin-top: 33px;
//       margin-bottom: 11px;
//     }
//     .profit_oriented_content_left_coursesName {
//       font-size: 32px;
//       font-weight: 400;
//     }
//   }
//   .profit_oriented_content_right {
//     width: 304px;
//     font-size: 21px;
//     font-weight: 400;
//     line-height: 37px;
//     margin-right: 45px;
//   }
// }
// .membership {
//   .member_top,
//   .member_bottom {
//     display: flex;
//     cursor: pointer;
//     &:hover {
//       background-color: #1651dd;
//       color: #ffffff;
//     }
//     &:hover .triangle {
//       background-color: #1651dd;
//       color: #ffffff;
//     }
//     &:hover .tag_container {
//       border: 1px solid #ffffff;
//     }
//   }
//   flex: 1;
//   display: flex;
//   flex-direction: column;
//   flex-wrap: wrap;
//   .home-course-content-container {
//     width: 389px;
//     height: 389px;
//     padding-left: 53px;
//     display: flex;
//     flex-direction: column;
//     position: relative;

//     .triangle {
//       position: absolute;
//       background-color: #ffffff;
//       top: 90px;
//       height: 50px;
//       width: 50px;
//       transform: rotate(45deg);
//     }
//     .left {
//       left: -25px;
//     }
//     .right {
//       right: -25px;
//     }
//   }
//   .tag {
//     margin-top: 45px;
//   }
//   .title {
//     font-size: 32px;
//     font-weight: 400;
//     line-height: 44px;
//     margin-top: 49px;
//   }
//   .content {
//     width: 279px;
//     font-size: 21px;
//     font-weight: 400;
//     line-height: 37px;
//     margin-top: 32px;
//   }
//   .box2 {
//     width: 389px;
//     height: 389px;
//   }
//   .box3 {
//     width: 389px;
//     height: 389px;
//   }
//   img {
//     width: 100%;
//     height: 100%;
//   }
// }
</style>
