<template>
  <div class="home-mentor-container">
    <div class="prev_mentor_title">
      <div class="title_left">
        泉邦导师介绍
      </div>
      <div class="title_right">
        了解更多<span class="iconfont icon-xiangyou3"></span>
      </div>
    </div>
    <div class="prev_mentor_list">
      <div class="recommendPage">
        <swiper :options="swiperOption" ref="mySwiper">
          <swiper-slide v-for="(item, index) in mentorList" :key="index">
            <img class="character_image" :src="item.src" alt="" />
          </swiper-slide>
          <div class="swiper-button-prev" slot="button-prev"></div>
          <div class="swiper-button-next" slot="button-next"></div>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
export default {
  name: 'HomeMentor',
  components: {
    swiper,
    swiperSlide
  },
  data() {
    return {
      mentorList: [
        {
          id: 1,
          src: require('@/assets/images/home/mentor_1.png')
        },
        {
          id: 2,
          src: require('@/assets/images/home/mentor_2.png')
        },
        {
          id: 3,
          src: require('@/assets/images/home/mentor_3.png')
        },
        {
          id: 4,
          src: require('@/assets/images/home/mentor_4.png')
        },
        {
          id: 5,
          src: require('@/assets/images/home/mentor_5.png')
        },
        {
          id: 6,
          src: require('@/assets/images/home/mentor_6.png')
        },
        {
          id: 7,
          src: require('@/assets/images/home/mentor_7.png')
        },
        {
          id: 8,
          src: require('@/assets/images/home/mentor_8.png')
        },
        {
          id: 9,
          src: require('@/assets/images/home/mentor_9.png')
        }
      ],
      distance: 0,
      num: 0,
      swiperOption: {
        loop: true,
        slidesPerView: 'auto',
        spaceBetween: 43,
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        },
        // 设置点击箭头
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.home-mentor-container {
  width: 100%;
  height: 914px;
  background: #ffffff;
  padding-left: 181px;
  margin-bottom: 107px;
}
.prev_mentor_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 53px;
  width: 100%;
  padding-bottom: 8px;
  border-bottom: 3px solid #d8d8d8;
  margin-top: 64px;
  margin-bottom: 69px;

  .title_left {
    width: 483px;
    height: 53px;
    font-size: 37px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 53px;
  }

  .title_right {
    display: flex;
    font-size: 19px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 27px;
    margin-right: 181px;
    cursor: pointer;
    span {
      height: 27px;
      font-size: 19px;
      line-height: 27px;
    }
  }
}

.prev_mentor_list {
  display: flex;
  overflow: hidden;
  .scrollbar {
    width: 100%;
    display: flex;
    transition: all 1s ease;
  }
  .mentor_item {
    cursor: pointer;
    .character_image {
      width: 491px;
      height: 782px;
    }
  }
}

::v-deep .recommendPage {
  position: relative;
  height: 800px;
  overflow: hidden;
  .swiper-slide {
    width: 491px;
    height: 782px;
  }
  .swiper-button-prev {
    width: 85px;
    height: 85px;
    background: #d8d8d8;
    opacity: 0.46;
    background: url(~@/assets/images/case/case_prev_btn.png) no-repeat;
    background-size: 100%;
  }
  .swiper-button-next {
    width: 85px;
    height: 85px;
    background: #d8d8d8;
    opacity: 0.46;
    background: url(~@/assets/images/case/case_next_btn.png) no-repeat;
    background-size: 100%;
  }
  .character_image {
    cursor: pointer;
  }
}
</style>
