<template>
  <div class="qb_family_container w">
    <div class="family_title">泉邦标杆案例</div>
    <div class="family_carousel">
      <swiper :options="swiperOption" ref="mySwiper">
        <swiper-slide v-for="item in familyList" :key="item.id">
          <div class="family_item">
            <img :src="item.picture" alt="" />
          </div>
        </swiper-slide>
        <!-- <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div> -->
      </swiper>
    </div>
    <div class="btn" @click="$router.push('/home/family')">
      MORE
    </div>
  </div>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
export default {
  name: 'QbFamily',
  components: {
    swiper,
    swiperSlide
  },
  data() {
    return {
      swiperOption: {
        loop: true,
        slidesPerView: 6,
        spaceBetween: 50,
        autoplay: {
          delay: 1000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        },
        watchSlidesProgress: true
        // 设置点击箭头
        // navigation: {
        //   nextEl: '.swiper-button-next',
        //   prevEl: '.swiper-button-prev'
        // }
      },
      familyList: [
        {
          id: 1,
          picture: require('@/assets/images/home/home_family_1.png')
        },
        {
          id: 2,
          picture: require('@/assets/images/home/home_family_2.png')
        },
        {
          id: 3,
          picture: require('@/assets/images/home/home_family_3.png')
        },
        {
          id: 4,
          picture: require('@/assets/images/home/home_family_4.png')
        },
        {
          id: 5,
          picture: require('@/assets/images/home/home_family_5.png')
        },
        {
          id: 6,
          picture: require('@/assets/images/home/home_family_6.png')
        },
        {
          id: 7,
          picture: require('@/assets/images/home/home_family_7.png')
        },
        {
          id: 8,
          picture: require('@/assets/images/home/home_family_8.png')
        }
      ]
    }
  }
}
</script>

<style lang="less" scoped>
.qb_family_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 384px;
  width: 100%;
  margin-bottom: 72px;
  .family_img {
    width: 100%;
    height: 100%;
  }
  .family_title {
    font-size: 37px;
    font-weight: 500;
  }

  .family_carousel {
    width: 1126px;
    height: 140px;
    margin-top: 62px;
    .family_item {
      height: 66px;
    }
  }
  .btn {
    width: 228px;
    height: 57px;
    line-height: 57px;
    text-align: center;
    background: #00123f;
    border-radius: 5px;
    font-size: 32px;
    font-weight: 600;
    color: #ffffff;
    margin-top: 30px;
    cursor: pointer;
  }
}
</style>
